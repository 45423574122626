@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap');


.App {
  font-family: sans-serif;
  text-align: center;
}

iframe {

  width: 100%;
  height: 400px;
}